<template>
    <div>
        <v-container style="margin-top: 130px;  text-align: center;">
            <center>
                <h2 class="intro-text-head hidden-sm-and-down">Selecciona el plan que mejor se ajuste a ti</h2>
                <h2 class="intro-text-head-mb hidden-md-and-up">Selecciona el plan que mejor se ajuste a ti</h2>
            
                <p class="intro-text-head-desc mt-2 hidden-sm-and-down">Tanto si eres una <b>Startup</b> como si eres un <b>negocio en crecimiento</b>, tenemos todo lo que necesitas.<br>
                    <b>Elige tu propio plan</b>, diseñado para satisfacer tus necesidades y superar tus expectativas.</p>
                <p class="intro-text-head-desc-mb mt-8 hidden-md-and-up ml-2 mr-2" style="text-align: justify;">Tanto si eres una <b>Startup</b> como si eres un <b>negocio en crecimiento</b>, tenemos todo lo que necesitas.</p>
            </center>
        </v-container>
        <v-container fluid>
            <center>
                <v-row justify="center" align="center" class="">

                        <v-card  style="border-radius: 15px; height: 490px; width: 370px;">
                            <v-card-title class="ml-2" style="font-size: normal; font-weight: 600;">{{ plans[0].title }}</v-card-title>
                            <h1 class="ml-7 mb-3" style="font-weight: 800; font-size: 40px; text-align: left;">
                                {{ plans[0].price }}<span
                                    style="font-size: small; font-weight: lighter;">/month</span>
                            </h1>
                            
                            <v-list-item three-line class="mt-4">
                                <v-list-item-content class="ml-2" style="text-align: left;">
                                    <v-list-item-title v-for="(feature, fIndex) in plans[0].features" :key="fIndex"
                                        class="mb-2">
                                        <v-icon left size="15"
                                            color="#25D366">mdi-checkbox-marked-circle-outline</v-icon>
                                        <span style="font-size: small;">{{ feature }}</span>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-card-actions class="pt-5">
                                <v-btn @click="subscribePlan(index)" rounded color="black" block
                                    class="white--text">Start now</v-btn>
                            </v-card-actions>
                        </v-card>

                        <v-card class="custom-margins" style="border-radius: 15px; height: 580px; width: 370px; border: 2.5px solid #7579e7;">
                            <v-row>
                                <v-col>
                                    <v-card-title class="ml-2" style="font-size: normal; font-weight: 600;">{{ plans[1].title }}</v-card-title>
                                </v-col>
                                <v-col >
                                    <v-btn x-small depressed rounded color="#7579E7" class="ml-15 mt-5 white--text"><v-icon left x-small>mdi-star</v-icon>Best</v-btn>
                                </v-col>
                            </v-row>
                            
                            <h1 class="ml-7 mb-3" style="font-weight: 800; font-size: 40px; text-align: left;">{{ plans[1].price }}<span style="font-size: small; font-weight: lighter;">/month</span></h1>
                            <v-list-item three-line class="mt-4">
                                <v-list-item-content class="ml-2" style="text-align: left;">
                                    <v-list-item-title v-for="(feature, fIndex) in plans[1].features" :key="fIndex"
                                        class="mb-2">
                                        <v-icon left size="15"
                                            color="#25D366">mdi-checkbox-marked-circle-outline</v-icon>
                                        <span style="font-size: small;">{{ feature }}</span>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-card-actions class="pt-5">
                                <v-btn @click="subscribePlan(index)" rounded color="black" block
                                    class="white--text">Start FREE Trial</v-btn>
                            </v-card-actions>
                            <p style="font-size: x-small;">Cancel at anytime within the Free trial.</p>

                        </v-card>

                        <v-card style="border-radius: 15px; height: 580px; width: 370px;">
                            <v-card-title class="ml-2" style="font-size: normal; font-weight: 600;">{{ plans[2].title }}</v-card-title>
                            <h1 class="ml-7 mb-3" style="font-weight: 800; font-size: 40px; text-align: left;">{{ plans[2].price }}<span style="font-size: small; font-weight: lighter;">/month</span></h1>
                            
                            <v-list-item three-line class="mt-4">
                                <v-list-item-content class="ml-2" style="text-align: left;">
                                    <v-list-item-title v-for="(feature, fIndex) in plans[2].features" :key="fIndex" class="mb-2">
                                        <v-icon left size="15" color="#25D366">mdi-checkbox-marked-circle-outline</v-icon>
                                        <span style="font-size: small;">{{ feature }}</span>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-card-actions class="pt-5">
                                <v-btn @click="subscribePlan(index)" rounded color="black" block
                                    class="white--text">Start FREE Trial</v-btn>
                            </v-card-actions>
                            <p style="font-size: x-small;">Cancel at anytime within the Free trial.</p>

                        </v-card>

                </v-row>
            </center>
        </v-container>
        <center>
            <v-card style="width: fit-content; border-radius: 10px; margin-bottom: 200px;" class="mt-9 glass-card">
                <v-row>
                    <v-col style="max-width:20%;">
                        <v-img contain style="width: 25px;" class="ml-3"
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/ChatGPT_logo.svg/1024px-ChatGPT_logo.svg.png"></v-img>
                    </v-col>
                    <v-col style="text-align: left;">
                        <span style="font-size: small;">Powered by ChatGPT</span>
                    </v-col>
                </v-row>
            </v-card>
        </center>
    </div>
</template>

<script>
/* eslint-disable no-undef */
export default {
    name: 'HomeView',
    components: {},
    data() {
        return {

            ticksLabels: [
                '2,000',
                '5,000',
                '10,000',
                '40,000',
            ],
            plans: [
                {
                    title: 'Gratis',
                    price: '$0',
                    features: [
                        'Portal de empleo Personalizado',
                        '15 Publicaciones de Empleo activas',
                        '7.000 Aplicantes',
                        'Categorización del aplicante por etapa ',
                        'Almacenamiento Ilimitado*',
                        'Generación de codigos QR',
                        'Reporte general de Aplicantes y trabajos',
                        'Envio de emails de rechazo a candidatos',
                        'Miembros de equipo Ilimitados',
                    ]
                },
                {
                    title: 'Starter',
                    price: '$19',
                    features: [
                        'Portal de empleo Personalizado',
                        '50 Publicaciones de Empleo activas',
                        '20.000 Aplicantes',
                        'Categorización del aplicante por etapa ',
                        'Almacenamiento Ilimitado*',
                        'Generación de codigos QR',
                        'Reporte general de Aplicantes y trabajos',
                        'Envio de emails de rechazo a candidatos',
                        'Miembros de equipo Ilimitados',
                        'Descripciones de empleo con IA',
                        'Promoción Ilimitada en Google Jobs',
                        'Formulario de preguntas',
                        
                    ]
                },
                {
                    title: 'Premium',
                    price: '$55',
                    features: [
                        'Portal de empleo Personalizado',
                        '∞ Publicaciones de Empleo activas',
                        '∞ Aplicantes',
                        'Categorización del aplicante por etapa ',
                        'Almacenamiento Ilimitado*',
                        'Generación de codigos QR',
                        'Reporte general de Aplicantes y trabajos',
                        'Envio de emails de rechazo a candidatos',
                        'Miembros de equipo Ilimitados',
                        'Descripciones de empleo con IA',
                        'Promoción Ilimitada en Google Jobs',
                        'Formulario de preguntas',
                        
                    ]
                }
            ]
        };
    },
    methods: {
        subscribePlan(plan) {
            console.log("El usuario seleccionó el plan " + plan);
            this.$router.push("/Register")
        }
    }
}
</script>

<style scoped>
.v-btn {
    text-transform: none;
}

.intro-text-head {
    color: black;
    font-size: 40px;
    line-height: 65px;
    text-align: center;
    font-weight: 600;
}

.intro-text-head-mb {
    color: black;
    font-size: 30px;
    line-height: 45px;
    font-weight: 600;

}

.intro-text-head-desc {
    color: black;
    font-size: 18px;
    font-weight: 400;
}

.intro-text-head-desc-mb {
    color: black;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}

.glass-card {
    border: none;
    background-color: rgba(255, 255, 255, 0.431);
}

.glass-card::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-image: linear-gradient(45deg, rgb(255, 255, 255), black) 1;
    border-radius: 20px;
}

.custom-margins {
  margin: 20px; /* Ajusta el valor de acuerdo a tu necesidad */
  
}

@media (max-width: 600px) { /* Modifica el ancho según el tamaño del celular */
  .custom-margins {
    margin: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
</style>
