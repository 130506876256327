import Vue from "vue";
import Router from "vue-router";

// Importar las vistas
import home from "../views/home.vue";
import Terms from "../views/terms.vue";
import Privacy from "../views/privacy.vue";
import Pricing from "../views/pricing.vue";
import NotFound from "../views/notFoundView.vue";

// import personasLanding from '../views/personasLanding.vue'



Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: home,
    },
    {
      path: "/terms",
      name: "Terms",
      component: Terms,
    },
    {
      path: "/privacy",
      name: "Privacy",
      component: Privacy,
    },
    {
      path: "/pricing",
      name: "Pricing",
      component: Pricing,
    },
    // {
    //   path: '/personas', 
    //   name: 'personas',
    //   component: personasLanding,
    // },
    //Personas
    {
      path: '/register',
      name: 'Home',
      beforeEnter() {
        window.location.href = "https://app.pill.com.co/register?utm_source=email&utm_medium=email&utm_campaign=cold_email_B";
      }
    },
    {
      path: '*', // Ruta comodín
      name: 'NotFound',
      component: NotFound
    }
  ],
});

export default router;
