<template>
    <v-container>
        <v-card class="mx-auto mt-7 black-card hidden-sm-and-down" elevation="3" outlined>
            <v-row class="mb-8">
                <v-col class="ml-16">
                    <h2 style="color: white; font-weight: 600; font-size: 30px;" class="mt-13">Recibe hojas de <br>vida.</h2>
                    <p style="color: white; font-size: large;" class="mt-10"><v-icon left color="#25D366" small>mdi-check-circle</v-icon>Acepta y <v-icon right color="red" small>mdi-close-circle</v-icon> Rechaza <br>candidatos rápidamente.</p>

                    <v-btn @click="goToRegister()" rounded class="mt-9 white--text" color="#7579E7">
                        Iniciar ahora <v-icon right color="white">mdi-chevron-right</v-icon>
                    </v-btn>
                </v-col>
                <v-col style="max-width: 700px;">
                    <v-img contain src="https://d153chakadu90d.cloudfront.net/web/cv_view.webp" height="110%"
                        alt="Custom ChatGPT chat for business example 2"></v-img>
                </v-col>
            </v-row>
        </v-card>

        <v-card class="mx-auto mx-1 hidden-md-and-up black-card mt-7" elevation="3" outlined>
            <v-row class="mt-7">
                <v-img src="https://d153chakadu90d.cloudfront.net/web/cv_view.webp"
                    alt="Custom ChatGPT Assistant chat for business"></v-img>
            </v-row>
            <v-row class="mt-7">
                <h2 style="color: white; font-weight: 600; font-size: 25px;" class="ml-5 mt-3">Recibe hojas de vida.</h2>
                <p style="color: white;" class="ml-5 mt-3"><v-icon left color="#25D366" small>mdi-check-circle</v-icon>Acepta y <v-icon right color="red" small>mdi-close-circle</v-icon> Rechaza <br>candidatos rápidamente.</p>
            </v-row>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="goToRegister()" rounded class="mt-5 ml-5 white--text" color="#7579E7">
                    Iniciar Ahora<v-icon right>mdi-chevron-right</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>


export default {
    name: 'InfoEmpresas',
    components: {
    },
    methods: {
        goToRegister() {
            window.open('https://app.pill.com.co/register', "_self");
        },
    }
}
</script>

<style scoped>
.v-btn {
    text-transform: none;
}

.title-style {
    color: black;
    font-size: 35px;
    line-height: 45px;
    text-align: center;
    font-weight: 600;
}

.title-style-mb {
    color: black;
    font-size: 28px;
    line-height: 45px;
    text-align: center;
    font-weight: 600;
}

.intro-text-head-desc {
    color: black;
    font-size: 20px;
    font-weight: 400;
}

.intro-text-head-desc-mb {
    color: black;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}



.black-card {
    border: none;
    border-radius: 20px !important;
    padding: 20px;
    background-color: #1B1B1B;
}
</style>
