<template>
    <v-container>
        <v-card class="mx-auto mt-7 hidden-sm-and-down black-card" elevation="3" outlined>
            <v-row>
                <v-col style="max-width: 700px;">
                    <v-img contain class="mt-8" src="https://d153chakadu90d.cloudfront.net/web/applications_view.webp"
                        height="90%" alt="Custom ChatGPT chat for business example 1"></v-img>
                </v-col>
                <v-col>
                    <h2 style="color: white; font-weight: 600; font-size: 30px;" class="mt-13">Administra tus
                        <br>candidatos.
                    </h2>
                    <p style="color: white;" class="mt-10 mr-6 p-text-pc">Los candidatos pueden<br> aplicar fácil y
                        rápido <v-icon left color="white">mdi-lightning-bolt</v-icon></p>

                    <v-btn @click="goToRegister()" rounded class=" mt-3 mb-6 white--text" color="#7579E7">
                        Iniciar Gratis<v-icon right>mdi-chevron-right</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>

        <v-card class="mx-auto mx-1 mt-7 black-card  hidden-md-and-up" elevation="3" outlined>
            <v-row class="mt-3">
                <v-img contain src="https://d153chakadu90d.cloudfront.net/web/applications_view.webp" width="10px"
                    alt="Custom ChatGPT Assistant chat for business"></v-img>
            </v-row>
            <v-row>
                <h2 style="color: white; font-weight: 600; font-size: 25px;" class="ml-5 mt-3">Administra tus candidatos.</h2>
                <p style="color: white;" class="ml-5 mt-3">Los candidatos pueden aplicar fácil y rápido <v-icon left
                        color="white">mdi-lightning-bolt</v-icon></p>
            </v-row>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="goToRegister()" rounded class="mt-5 ml-5 white--text" color="#7579E7">
                    Iniciar Gratis<v-icon right>mdi-chevron-right</v-icon>
                </v-btn>
            </v-card-actions>

        </v-card>

        <center>
            <v-card rounded class="mb-15 mt-15 hidden-sm-and-down" color="white"
                style="width: 900px; height: 170px; border-radius: 20px !important;">
                    <v-row>
                        <v-col style="text-align: left; max-width: fit-content;" class="ml-10">
                            <h2 style="color: black; font-weight: 400; font-size: 30px;" class="my-3 mt-8"><b>Pill:</b> La solución al dolor de cabeza de recibir CVs por correo.</h2>
                        </v-col>
                        <v-col style="padding-top: 70px; max-width: 30%;" class="ml-10">

                            <v-btn @click="goToRegister()" rounded class="white--text" color="#7579E7">
                                Registrarme<v-icon right>mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
            </v-card>

            <v-card rounded class="mb-16 mt-16 hidden-md-and-up" color="white" style="border-radius: 20px !important;">
                    <v-row>
                        <h2 style="color: black; font-weight: 400; font-size: 22px; max-width: 80%;" class="mt-7 mx-auto"><b>Pill:</b> La solución al dolor de cabeza de recibir CVs por correo.</h2>
                    </v-row>
                    <v-row>
                        <v-btn @click="goToRegister()" rounded class="mx-auto ma-5 white--text" color="#7579E7">
                            Registrarme<v-icon right>mdi-chevron-right</v-icon>
                        </v-btn>
                    </v-row>
            </v-card>
        </center>
    </v-container>
</template>

<script>

// @ is an alias to /src  
// 

export default {
    name: 'MultipurposeView',
    components: {
    },
    methods: {
        goToRegister() {
            window.open('https://app.pill.com.co/register', "_self");
        },
    }
}
</script>

<style scoped>
.black-card {
    border: none;
    border-radius: 20px !important;
    padding: 20px;
    background-color: #1B1B1B;
}

.p-text-pc {
    font-size: 1.2rem;
}

.v-btn {
    text-transform: none;
}

.title-style {
    color: #ffffff;
    font-size: 35px;
    line-height: 45px;
    text-align: center;
    font-weight: 600;
}

.title-style-mb {
    color: #ffffff;
    font-size: 28px;
    line-height: 45px;
    text-align: center;
    font-weight: 600;
}

.intro-text-head-desc {
    color: white;
    font-size: 20px;
    font-weight: 400;
}

.intro-text-head-desc-mb {
    color: white;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}
</style>
