<template>
    <!-- If using vue-router -->
    <router-view>
      <router-view />
    </router-view>
</template>
<script>
export default {
  name: 'App',
  mounted() {
    // const script = document.createElement('script');
    // script.src = 'https://d153chakadu90d.cloudfront.net/web/chat.js';
    // script.id = 'R1YABxOvUHIBSQcFMO23';
    // document.body.appendChild(script);
  },
}
</script>