import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
// import VueScrollTo from 'vue-scrollto'
import VueSimpleAlert from "vue-simple-alert";
// import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import './styles.scss'


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCQ9CuhAUkjOqtnh2-kqJlL3EOg2AnwulA",
  authDomain: "pill-website.firebaseapp.com",
  projectId: "pill-website",
  storageBucket: "pill-website.appspot.com",
  messagingSenderId: "429830022829",
  appId: "1:429830022829:web:949e8260d18567bb6ac824",
  measurementId: "G-NDW14FWZ3W"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

Vue.config.productionTip = false
Vue.use(VueSimpleAlert);


new Vue({
  vuetify,
  router,
  render: h => h(App)
  
}).$mount('#app')




