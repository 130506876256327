<template>
    <div>
        <!--Medida más pequeña 360x740-->
        <v-app-bar class="top hidden-md-and-up mt-3 ml-1 mr-1" app style="background: rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: blur(13px);
    backdrop-filter: blur(13px);
    border: 1px solid rgba(255, 255, 255, 0.05); border-radius: 15px;" elevation="0" height="75">
            <v-app-bar-nav-icon light @click.stop="dialogMenu = true"></v-app-bar-nav-icon>

            <v-img src="https://d153chakadu90d.cloudfront.net/web/Pill_logo_final.webp" class=""
                @click="redirectToHome()" max-height="100" max-width="100" alt="Oportunidades logo"></v-img>

            <v-spacer></v-spacer>

            <v-btn @click="goToRegister()" rounded color="#7579E7" class="mr-2 white--text">
                Registro <v-icon right>mdi-chevron-right</v-icon>
            </v-btn>
        </v-app-bar>

        <!--PC-->
        <v-app-bar class="hidden-sm-and-down mt-3 ml-10 mr-10" app style="background: rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: blur(13px);
    backdrop-filter: blur(13px);
    border: 1px solid rgba(255, 255, 255, 0.05); border-radius: 20px;" elevation="0" height="75" >
            <v-img class="ml-3" src="https://d153chakadu90d.cloudfront.net/web/Pill_logo_final.webp"
                @click="redirectToHome()" max-height="100" max-width="100" contain alt="Logo AI"></v-img>
            <v-spacer></v-spacer>
            <v-list class="d-flex align-center" style="background-color: transparent">
                <!-- <v-list-item class="mr-15">
                    <a :href="'https://pill.com.co/personas'" target="_blank" style="text-decoration: none;">
                        <v-list-item-title style="font-size: 17px; color: black; font-weight: 500;">Para Personas</v-list-item-title>
                    </a>
                </v-list-item> -->
                <v-list-item class="mr-15">
                    <a :href="'https://blog.pill.com.co'" target="_blank" style="text-decoration: none;">
                        <v-list-item-title style="font-size: 17px; color: black; font-weight: 500;">Blog</v-list-item-title>
                    </a>
                </v-list-item>
                <v-list-item class="mr-15">
                    <a :href="'https://app.pill.com.co/login'" style="text-decoration: none;">
                        <v-list-item-title style="font-size: 17px; color: black; font-weight: 500;">Log in</v-list-item-title>
                    </a>
                </v-list-item>
            </v-list>
            <v-btn @click="goToRegister()" rounded color="#7579E7" class="mr-10 white--text">
                Registro <v-icon right>mdi-chevron-right</v-icon>
            </v-btn>
        </v-app-bar>
        <!--MENU MOBILE-->
        <v-dialog v-model="dialogMenu" width="400" transition="dialog-top-transition">
            <v-card class="glass-card" elevation="10" outlined style="height: 280px; ">
                <center>
                    <p style="font-size: 25px; font-weight: 450; color: white;" class="mx-auto">Menu</p>
                    <v-divider color="white"></v-divider>
                </center>
                <v-btn @click="goToRegister()" block depressed big color="black" class="mt-10" dark x-large rounded>
                    Register
                </v-btn>
                <a href='https://app.pill.com.co/login' style="text-decoration: none;">
                    <v-btn block depressed big color="black" dark class="mt-3" x-large rounded>
                        Log In
                    </v-btn>
                </a>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
//import HelloWorld from "./components/HelloWorld";

export default {
    name: "App",
    data() {
        return {
            dialogMenu: false,
        }
    },

    methods: {

        GoToHome() {
            this.$router.push("/")
        },
        goToRegister() {
            window.open('https://app.pill.com.co/register', "_self");
        },
    }
};
</script>

<style scoped>
.v-btn {
    text-transform: none;
}

@media (min-width: 1040px) {
    .v-app-bar {
        margin-left: 30px !important;
    }
}

.glass-card {
    border-radius: 16px !important;
    border-color: white;
    padding: 20px;
    background-color: rgb(7, 0, 0);
}
</style>