<template>
    <div>
        <v-app>
            <Header />
            <v-main style="padding: 0px 0px 0px;">
                <v-container style="margin-top: 130px; margin-bottom: 200px; text-align: center;">
                    <center>
                        <h2 class="intro-text-head hidden-sm-and-down">Privacy policy</h2>
                        <h2 class="intro-text-head-mb hidden-md-and-up">Privacy policy</h2>

                        <v-card class="glass-card mt-12" style="border-radius: 20px; ">
                            <br>
                            <v-row>
                                <v-col class="ml-8 mr-8">
                                    <p style="color: black; text-align: justify;">At Pill Applicant Tracking System,
                                        accessible from
                                        https://pill.com.co, one of our main priorities is the privacy of our visitors.
                                        This Privacy Policy document contains types of information that is collected and
                                        recorded by Pill Applicant Tracking System and how we use it.
                                        If you have additional questions or require more information about our Privacy
                                        Policy, do not hesitate to contact us.
                                        This Privacy Policy applies only to our online activities and is valid for
                                        visitors
                                        to our website with regards to the information that they shared and/or collect
                                        in
                                        Pill Applicant Tracking System. This policy is not applicable to any information
                                        collected offline or
                                        via channels other than this website.</p>

                                    <h2 style="color: black; font-weight: 600; text-align: left;" class="mb-5">Consent
                                    </h2>
                                    <p style="color: black; text-align: justify;">By using our website/App, you hereby
                                        consent
                                        to our Privacy Policy and agree to its terms.</p>

                                    <h2 style="color: black; font-weight: 600; text-align: left;" class="mb-5">
                                        Information
                                        we collect</h2>
                                    <p style="color: black; text-align: justify;">The personal information that you are
                                        asked to provide, and the reasons why you are asked to provide it, will be made
                                        clear to you at the point we ask you to provide your personal information.
                                        If you contact us directly, we may receive additional information about you such
                                        as
                                        your name, email address, the contents of the message and/or attachments you may
                                        send us, and any other information you may choose to provide.
                                        When you register for an Account, we may ask for your contact information,
                                        including
                                        items such as name/company name, email address, industry and country.
                                    </p>

                                    <h2 style="color: black; font-weight: 600; text-align: left;" class="mb-5">How we
                                        use
                                        your information</h2>
                                    <p style="color: black; text-align: justify;">
                                        We use the information we collect in various ways, including to:
                                        <br>
                                        <br>
                                    <ul>
                                        <li>Provide, operate, and maintain our website.</li>
                                        <li>Improve, personalize, and expand our website.</li>
                                        <li>Understand and analyze how you use our website.</li>
                                        <li>Develop new products, services, features, and functionality.</li>
                                        <li>Communicate with you, either directly or through one of our partners,
                                            including
                                            for customer service, to provide you with updates and other information
                                            relating
                                            to the website, and for marketing and promotional purposes.</li>
                                        <li>Send you emails</li>
                                        <li>Find and prevent fraud</li>
                                    </ul>
                                    </p>




                                    <h2 style="color: black; font-weight: 600; text-align: left;" class="mb-5">GDPR Data
                                        Protection Rights</h2>
                                    <p style="color: black; text-align: justify;">
                                        We would like to make sure you are fully aware of all of your data protection
                                        rights. Every user is entitled to the following:
                                        <br>
                                        <br>
                                    <ul>
                                        <li><b>The right to access:</b> You have the right to request copies of your
                                            personal
                                            data.</li>
                                        <li><b>The right to rectification:</b> You have the right to request that we
                                            correct
                                            any
                                            information you believe is inaccurate. You also have the right to request
                                            that
                                            we complete the information you believe is incomplete.</li>
                                        <li><b>The right to erasure:</b> You have the right to request that we erase
                                            your
                                            personal
                                            data, under certain conditions.</li>
                                        <li><b>The right to restrict processing:</b> You have the right to request that
                                            we
                                            restrict
                                            the processing of your personal data, under certain conditions.</li>
                                        <li><b>The right to object to processing:</b> You have the right to object to
                                            our
                                            processing
                                            of your personal data, under certain conditions.</li>
                                        <li><b>The right to data portability:</b> You have the right to request that we
                                            transfer
                                            the data that we have collected to another organization, or directly to you,
                                            under certain conditions.</li>
                                    </ul>





                                    <br>
                                    If you make a request, we have one month to respond to you. If you would like to
                                    exercise any of these rights, please contact us.
                                    </p>

                                    <h2 style="color: black; font-weight: 600; text-align: left;" class="mb-5">
                                        Children's
                                        Information</h2>
                                    <p style="color: black; text-align: justify;">
                                        Another part of our priority is adding protection for children while using the
                                        internet. We encourage parents and guardians to observe, participate in, and/or
                                        monitor and guide their online activity.
                                        Pill Applicant Tracking System does not knowingly collect any Personal
                                        Identifiable Information from
                                        children under the age of 13. If you think that your child provided this kind of
                                        information on our website, we strongly encourage you to contact us immediately
                                        and
                                        we will do our best efforts to promptly remove such information from our
                                        records.
                                    </p>

                                    <h2 style="color: black; font-weight: 600; text-align: left;" class="mb-5">
                                        Information We Collect</h2>
                                    <p style="color: black; text-align: justify;">
                                        Personal Data: When you or Applicants use our Service, we may collect personal
                                        data, such as
                                        names, contact details, resumes, job application details, and other information
                                        provided by candidates or clients.

                                        Usage Data: We collect information about your interactions with our Service,
                                        including IP addresses, browser types, operating systems, and usage patterns.
                                        Cookies and Tracking Technologies: We use cookies and similar technologies to
                                        track user activity and preferences to enhance your experience.
                                    </p>
                                    <h2 style="color: black; font-weight: 600; text-align: left;" class="mb-5">How We
                                        Share Your Information</h2>
                                    <p style="color: black; text-align: justify;">

                                        We may share your information in the following circumstances:<br><br>

                                        With Service Providers: We may share information with third-party service
                                        providers who assist us in operating our Service, such as hosting providers and
                                        customer support services.<br><br>
                                        For Legal Reasons: We may disclose information if required by law or in response
                                        to valid requests by public authorities (e.g., a subpoena).<br><br>
                                        Business Transfers: In the event of a merger, acquisition, or other business
                                        transition, we may transfer information to the successor organization.<br>
                                    </p>
                                    
                                    <h2 style="color: black; font-weight: 600; text-align: left;" class="mb-5">General terms</h2>
                                    <p style="color: black; text-align: justify;">
                                         
                                        Information of the Data Controller: Pill Applicant Tracking System: “pill.com.co”. In the development of its corporate purpose and economic
                                        activity, it acts as the Data Controller of personal data contained in its
                                        databases. Consequently, it collects, stores, uses, transmits, transfers, and
                                        deletes personal data of natural or legal persons with whom it has or has had
                                        any
                                        type of relationship, whether civil, commercial, or labor; including, but not
                                        limited to, its suppliers, clients, employees, contractors, creditors, debtors,
                                        and
                                        shareholders through various means such as physical or virtual (hereinafter
                                        “personal information”).<br><br>

                                        USE of the Privacy Policy – Scope of application according to the quality held:
                                        Human Resources: Pill Applicant Tracking System processes personal information of candidates, third
                                        parties linked for the provision of services, employees and their family
                                        members, and retired employees as follows:<br><br>

                                        By accepting this Privacy Policy, the candidate, third party, employee, and
                                        retired
                                        employee, as the data subject, authorizes Pill Applicant Tracking System and the designated third
                                        parties to process the data, either partially or fully, including collection,
                                        storage, recording, use, circulation, processing, deletion, transmission, and/or
                                        transfer to third parties under the terms of this Privacy Policy for the
                                        purposes
                                        described therein, especially—but not exclusively—in the following cases:<br><br>

                                        Sending emails, information related to selection processes, management of
                                        available
                                        vacancies at Pill Applicant Tracking System, hiring processes, and/or any other information
                                        directly or
                                        indirectly related to fulfilling obligations related to human resources
                                        management.<br><br>
                                        Performing verification and validation processes of the information, conducting
                                        security studies, requesting personal and/or employment references, disciplinary
                                        and/or judicial records, or those related to restrictive lists of risks, money
                                        laundering prevention, corruption, and terrorism financing, as well as other
                                        checks
                                        related to the eventual work to be performed.<br><br>
                                        Consulting and updating personal information and data at any time while the
                                        information is retained in the database.<br><br>
                                        Processing personal data of retired employees is exempt from authorization as it
                                        constitutes a legal obligation for companies under Article 2.2.44.6.13 of Decree
                                        1072 of 2015; however, this does not preclude compliance with Colombian law
                                        principles regarding data protection. Therefore, retired employees' data will be
                                        processed in accordance with this Privacy Policy.<br><br>

                                        Providers: For the fulfillment of its activities and projects, Pill Applicant Tracking System
                                        relies on
                                        providers of products and services. Providers may be natural or legal persons.<br><br>

                                        Provision of Products: Personal information of providers who are natural persons
                                        will be incorporated into a Pill Applicant Tracking System database. Contracts with natural persons
                                        will constitute unequivocal authorization for the processing of their personal
                                        data
                                        by Pill Applicant Tracking System. The processing of personal information will be carried out in
                                        accordance with this Privacy Policy. For contracts described in this section
                                        with
                                        legal persons, Pill Applicant Tracking System will receive personal information about employees,
                                        contractors, subcontractors, and/or administrators of the legal entity. This
                                        Data
                                        Transfer is necessary for the execution of the contract; in any case,
                                        Pill Applicant Tracking System
                                        will strive, if possible, for the provider to deliver only corporate data, which
                                        Pill Applicant Tracking System will include in a database and process according to this privacy
                                        policy.<br><br>

                                        Provision of Services: Data of natural persons who provide services directly to
                                        Pill Applicant Tracking System, or through a contract with a legal entity, will be processed in
                                        accordance with the section on linked third parties. General Terms for
                                        Processing
                                        Data of All Natural Persons: At Pill Applicant Tracking System, we are committed to the security
                                        and
                                        proper use of information, thus, we strive to use it appropriately and maintain
                                        its
                                        confidentiality according to this Privacy Policy. Personal information may be
                                        transferred and/or transmitted to third parties and judicial or administrative
                                        authorities and to our affiliates and/or related entities, whether natural or
                                        legal
                                        persons, Colombian or foreign.<br><br>

                                        In cases where data transfer or transmission is necessary to carry out
                                        authorized
                                        uses and activities by the data subjects according to Pill Applicant Tracking System's corporate
                                        purpose or related activities, the information will be kept under strict
                                        confidentiality and will be processed rigorously, respecting the rights and
                                        guarantees of its data subjects.<br><br>

                                        Pill Applicant Tracking System may transfer personal data of data subjects as part of the company's
                                        assets to its affiliates and/or related entities, so they can process them under
                                        the
                                        terms and conditions established in their own privacy policy, which will not
                                        lower
                                        the confidentiality standards set out in this policy. In case of such transfer,
                                        Pill Applicant Tracking System will verify that the new data controller has a privacy and data
                                        processing policy that complies with current HABEAS DATA regulations. However,
                                        once
                                        the transfer or circulation is made, Pill Applicant Tracking System will not be responsible for the
                                        subsequent processing by the data recipient.<br><br>

                                        Specifically, but not limited to, we inform you that we may use service
                                        providers
                                        and data processors working on behalf of Pill Applicant Tracking System for the development of
                                        activities and businesses. These services may include, among others, system
                                        hosting
                                        and maintenance, encryption, analysis, email messaging, delivery, payment
                                        transaction management, and credit and address controls. Therefore, the
                                        authorization granted by Pill Applicant Tracking System and its affiliates and/or related entities
                                        for
                                        the processing of your personal data includes the right to transmit your data to
                                        necessary third parties for the execution of these services. In this scenario,
                                        Pill Applicant Tracking System and its affiliates and/or related entities, as data controllers,
                                        will
                                        include in their contracts the obligations of a third-party data processor.<br><br>

                                        However, it is important to clarify that Pill Applicant Tracking System makes its best efforts to
                                        ensure that both service providers and processors working on our behalf, as well
                                        as
                                        other authorized third parties, process personal data in accordance with this
                                        privacy policy, protect the confidentiality of the information with their
                                        personnel,
                                        and generally respect regulations concerning personal data protection.<br><br>

                                        The data subject will be responsible for the accuracy of the provided data, with
                                        Pill Applicant Tracking System reserving the right to exclude any data subject from registered
                                        services
                                        if false data has been provided. Pill Applicant Tracking System may collect publicly available
                                        information to complement its databases. This information will be treated in the
                                        same manner.<br>

                                    </p>
                                    
                                    <h2 style="color: black; font-weight: 600; text-align: left;" class="mb-5">Truthfulness of the Information</h2>
                                    <p style="color: black; text-align: justify;">

                                        All individuals providing personal information to Pill Applicant Tracking System must provide
                                        truthful information. Pill Applicant Tracking System presumes the good faith and accuracy of the
                                        information provided by the Data Subjects and will not assume any responsibility
                                        regarding incorrect, false, or inaccurate information.<br>
                                    </p>
                                    
                                    <h2 style="color: black; font-weight: 600; text-align: left;" class="mb-5">Data Retention Period</h2>
                                    <p style="color: black; text-align: justify;">
                                    
                                        The databases of Pill Applicant Tracking System will have a validity period corresponding to the
                                        purpose for which their processing was authorized or, failing that, THIRTY (30)
                                        years.<br>
                                    </p>
                                    
                                    <h2 style="color: black; font-weight: 600; text-align: left;" class="mb-5">Mechanisms for Protection and Privacy of Your Personal Information at
                                        Pill Applicant Tracking System</h2>
                                    <p style="color: black; text-align: justify;">
                                        Pill Applicant Tracking System is committed to keeping the information and its content strictly
                                        confidential and stored on servers that meet the necessary security standards to
                                        prevent its alteration, loss, consultation, unauthorized or fraudulent use or
                                        access, thus ensuring the exercise of the right to habeas data. While we strive
                                        to protect your personal information, Pill Applicant Tracking System cannot guarantee that your
                                        personal information or communications will always be private.<br><br>

                                        All information stored by Pill Applicant Tracking System is managed with absolute confidentiality
                                        and in accordance with current Colombian legislation, including all security
                                        measures outlined later. However, if registered, it is your responsibility to
                                        keep your password confidential.<br>

                                    </p>
                                    
                                    <h2 style="color: black; font-weight: 600; text-align: left;" class="mb-5">Confidentiality of Personal Information</h2>
                                    <p style="color: black; text-align: justify;">

                                        The personal information provided by the Data Subject will be used by us, our
                                        affiliates, and/or related parties and authorized third parties for such
                                        purposes in the manner established both in the authorization and this Privacy
                                        Policy and will not be used for purposes other than those for which it was
                                        provided. We will strive to protect the privacy of personal information. We will
                                        retain the information under necessary security conditions to prevent its
                                        alteration, loss, consultation, unauthorized or fraudulent use or access, and
                                        respect the rights of the Data Subjects.<br><br>

                                        If for any reason a competent authority requests personal information, we will
                                        notify you of such an order in advance unless the authority prohibits us from
                                        doing so.<br>

                                        
                                    </p>






                                    <h2 style="color: black; font-weight: 600; text-align: left;" class="mb-5">Data
                                        Security</h2>
                                    <p style="color: black; text-align: justify;">
                                        We implement reasonable measures to protect your information from unauthorized
                                        access, disclosure, or destruction. However, no security system is impenetrable,
                                        and we cannot guarantee the absolute security of your data.<br>
                                    </p>


                                    <h2 style="color: black; font-weight: 600; text-align: left;" class="mb-5">Data
                                        Retention</h2>
                                    <p style="color: black; text-align: justify;">
                                        We retain personal data for as long as necessary to fulfill the purposes
                                        outlined in this Privacy Policy unless a longer retention period is required or
                                        permitted by law.<br>
                                    </p>
                                    <h2 style="color: black; font-weight: 600; text-align: left;" class="mb-5">Changes
                                        to This Privacy Policy</h2>
                                    <p style="color: black; text-align: justify;">
                                        We may update this Privacy Policy from time to time. We will notify you of any
                                        changes by posting the new Privacy Policy on our website or through other means
                                        of communication.
                                    </p>
                                </v-col>
                            </v-row>

                        </v-card>
                    </center>
                </v-container>

            </v-main>
            <Footer />

        </v-app>
    </div>
</template>

<script>
import Header from '@/components/components_landing_home/headerHome.vue'
import Footer from '@/components/components_landing_home/Footer.vue'

// @ is an alias to /src
export default {
    name: 'termsView',
    components: {
        Header,
        Footer
    },
}
</script>

<style scoped>
.v-btn {
    text-transform: none;
}

.intro-text-head {
    color: black;
    font-size: 40px;
    line-height: 65px;
    text-align: center;
    font-weight: 600;
}

.intro-text-head-mb {
    color: black;
    font-size: 37px;
    line-height: 45px;
    font-weight: 600;

}

.intro-text-head-desc {
    color: black;
    font-size: 18px;
    font-weight: 400;
}

.intro-text-head-desc-mb {
    color: black;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}

.glass-card {
    border: none;
    background-color: rgba(255, 255, 255, 0.3);
}

.glass-card::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-image: linear-gradient(45deg, rgb(255, 255, 255), black) 1;
    border-radius: 20px;
}
</style>