<template>
  <div>
    <v-app>
      <Header />
      <NotFoundPage />
      <Footer />

    </v-app>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/components_landing_home/headerHome.vue'
import Footer from '@/components/components_landing_home/Footer.vue'
import NotFoundPage from '@/components/components_landing_home/404Page.vue'


export default {
  name: 'HomeView',
  components: {
    Header,
    Footer,
    NotFoundPage
  },
}
</script>
