<template>
  <v-footer style="background-color: white;">
    <v-card elevation="0" tile width="100%" style="background-color: #EFEFEF; border-radius: 20px !important;"
      class="white--text text-center mb-3 hidden-sm-and-down">
      <v-row align="center" justify="space-between" no-gutters class="mt-5  align-start">
        <v-col cols="2" class="ml-5" style="width: 1500px;">
          <div style="text-align: left;">
            <img src="https://d153chakadu90d.cloudfront.net/web/Pill_logo_final.webp" contain
              alt="Pill Oportunidades logo" style="max-width: 45%; height: auto; ">
            <p class="ml-4" style="color: black; font-size: small;">Connecting people with opportunities.</p>
            <a :href="socials[0].url" style="text-decoration: none;"><v-btn class="ml-2 elevation-0" icon><v-icon
                  color="black">mdi-linkedin</v-icon></v-btn></a>
          </div>
        </v-col>
        <v-col cols="2" class="text-center">
          <div style="text-align: left;">
            <h3 class="footer-title mb-3">Platform</h3>
            <a href="https://pill.com.co/privacy" style="text-decoration: none;">
              <p class="link-style">Privacy Policy</p>
            </a>
            <a href="https://pill.com.co/terms" style="text-decoration: none;">
              <p class="link-style">Terms & Conditions</p>
            </a>
            <a href="https://pill.com.co/pricing" style="text-decoration: none;">
              <p class="link-style">Pricing</p>
            </a>

          </div>

        </v-col>
        <v-col cols="2" class="text-center">
          <div style="text-align: left; margin-bottom: 95px;">
            <h3 class="footer-title mb-3">Company</h3>
            <a href="https://app.pill.com.co/pill" style="text-decoration: none;">
              <p class="link-style">Careers</p>
            </a>

          </div>
        </v-col>
        <v-col cols="2" class="text-center">
          <div style="text-align: left; margin-bottom: 95px;">
            <h3 class="footer-title mb-3">Contact</h3>
            <a href="mailto:pill.startup@gmail.com" style="text-decoration: none;">
              <p class="link-style">Email us!</p>
            </a>
          </div>
        </v-col>
        <v-col cols="1" style="margin-right: 5rem;">
          <img class="mb-16" src="https://d153chakadu90d.cloudfront.net/web/microsoft_for_startups.webp"
            href="https://www.microsoft.com/en-us/startups" alt="Pill Oportunidades - Microsoft for startups"
            style="max-width: 140%; height: auto; ">
        </v-col>
      </v-row>

      <v-card-text style="background-color: #EFEFEF; color: black;" class="">
        © {{ new Date().getFullYear() }} Pill Oportunidades - All rights reserved.
      </v-card-text>
    </v-card>

    <v-card elevation="0" tile width="100%" style="background-color: #EFEFEF; border-radius: 20px !important;"
      class="white--text text-center mb-3 hidden-md-and-up">
      <div class="mt-3">
        <v-row>
          <v-col style="text-align: left;" class="ml-3">
            <img src="https://d153chakadu90d.cloudfront.net/web/Pill_logo_final.webp" contain
              alt="Pill Oportunidades logo" style="max-width: 60%; height: auto; ">
            <p class="ml-4" style="color: black; font-size: small;">Connecting people with opportunities.</p>
          </v-col>
          <v-col>
            <img src="https://d153chakadu90d.cloudfront.net/web/microsoft_for_startups.webp"
              href="https://www.microsoft.com/en-us/startups" alt="Pill Oportunidades - Microsoft for startups"
              style="max-width: 80%; height: auto; " class="mt-2">
          </v-col>
        </v-row>
        <div style="text-align: left;" class="ml-7 mt-3">
          <h3 class="footer-title mb-3">Platform</h3>
          <a href="https://pill.com.co/personas" style="text-decoration: none;">
            <p class="link-style">Para Personas</p>
          </a>
          <a href="https://pill.com.co/privacy" style="text-decoration: none;">
            <p class="link-style">Privacy Policy</p>
          </a>
          <a href="https://pill.com.co/terms" style="text-decoration: none;">
            <p class="link-style">Terms & Conditions</p>
          </a>
          <a href="https://pill.com.co/pricing" style="text-decoration: none;">
            <p class="link-style">Pricing</p>
          </a>
          <h3 class="footer-title mb-3 mt-4">Company</h3>
          <a href="https://app.pill.com.co/pill" style="text-decoration: none;">
            <p class="link-style">Careers</p>
          </a>
          <h3 class="footer-title mb-3 mt-4">Contact</h3>
          <a href="mailto:pill.startup@gmail.com" style="text-decoration: none;">
            <p class="link-style">Email us!</p>
          </a>
        </div>
        <div style="text-align: left;" class="ml-3">
          <a :href="socials[0].url" style="text-decoration: none;"><v-btn class="ml-2 elevation-0" icon large><v-icon
                color="black">mdi-linkedin</v-icon></v-btn></a>
        </div>
      </div>

      <v-card-text style="background-color: #EFEFEF; color: black;" class="">
        © {{ new Date().getFullYear() }} Pill Oportunidades - All rights reserved.
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "Footer-",

  data: () => ({
    panel: [],
    socials: [
      { name: "linkedin", icon: "mdi-linkedin", url: "https://www.linkedin.com/company/pill-oportunidades/" },
      { name: "instagram", icon: "mdi-instagram", url: "https://www.instagram.com/chatpro.ai/" }
    ],

  }),

  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    onResize() {
      if (window.innerWidth >= 960) {
        this.panel = [...Array(4).keys()].map((k, i) => i);
      } else {
        this.panel = [];
      }
    }
  }
}

</script>

<style scoped>
.footer-title {
  font-weight: 500;
  color: black;
}

.link-style {
  color: black
}
</style>