<template>
    <div>
        <v-app>
            <Header />
            <v-main style="padding: 0px 0px 0px;">
                <v-container style="margin-top: 130px; margin-bottom: 200px; text-align: center;">
                    <center>
                        <h2 class="intro-text-head hidden-sm-and-down">Terms and Conditions</h2>
                        <h2 class="intro-text-head-mb hidden-md-and-up">Terms and Conditions</h2>

                        <p class="intro-text-head-desc mt-2 hidden-sm-and-down">By registering on our platform
                            <b>Pill.com.co</b> (Pill Applicant Tracking System), you
                            acknowledge and agree to adhere to the terms and conditions set forth.
                        </p>
                        <p class="intro-text-head-desc-mb mt-8 hidden-md-and-up ml-2 mr-2" style="text-align: justify;">
                            By
                            registering on our platform, you acknowledge and agree to adhere to the terms and conditions
                            set
                            forth.</p>

                        <v-card class="glass-card mt-12" style="border-radius: 20px; ">
                            <br>
                            <v-row>
                                <v-col class="ml-8 mr-8">

                                    <p style="color: black; text-align: justify;">The following Terms of Service,
                                        together with the Privacy Policy and all other policies referenced herein or in
                                        other documents to which they refer, constitute the legally binding agreement
                                        (this "Agreement") between pill.com.co ("The Company") and you ("you" or the
                                        "User") governing all use of the pill.com.co platform
                                        (website/application).<br><br>

                                        The terms "you" or the "user," as mentioned earlier, refer to all natural or
                                        legal persons or entities who use and access this website/application. Depending
                                        on how the website is used, they may also be referred to as employers ("the
                                        companies") or job seekers ("candidates").<br><br>

                                        By using this platform, you indicate your acceptance of these "Terms and
                                        Conditions."<br><br>
                                    </p>
                                    <h2 style="color: black; font-weight: 600; text-align: left;" class="mb-5">Billing
                                        and Refunds:</h2>
                                    <p style="color: black; text-align: justify;">
                                        Pill.com.co require payment for accessing some of our products through monthly
                                        subscription plans. Given the unique characteristics of our product, we regret
                                        to inform you that we do not currently provide any form of refunds, whether
                                        partial or complete. You have the freedom to effortlessly terminate your
                                        subscription whenever you desire. Once your subscription is canceled, no further
                                        charges will be incurred. Please note that we reserve the right to modify our
                                        pricing, pricing policies, features, and access restrictions at any given time.
                                    </p>

                                    <h2 style="color: black; font-weight: 600; text-align: left;" class="mb-5">General
                                        Conditions:</h2>
                                    <p style="color: black; text-align: justify;">
                                        Access to the website/application (hereinafter referred to as the "site"/"app")
                                        implies full and unconditional knowledge and acceptance of the terms and
                                        conditions set forth herein (hereinafter "General Conditions of Use").<br><br>

                                        This site has been developed and managed by pill.com.co. We reserve the right to
                                        discontinue and/or modify this website or the General Conditions of Use, in
                                        whole or in part.<br><br>

                                        Please note that pill.com.co may make changes at its discretion and without
                                        prior notice; therefore, we ask that the next time you visit this site/app, you
                                        review the General Conditions of Use to verify any changes or corrections that
                                        may have been made.<br><br>
                                    </p>

                                    <h2 style="color: black; font-weight: 600; text-align: left;" class="mb-5">Platform
                                        and Service Usage:</h2>
                                    <p style="color: black; text-align: justify;">

                                        Pill ATS provides a site/app as an online job board for any "User" using any
                                        aspect of the site/app as follows:<br><br>
                                    <ul>
                                        <li>A person accessing the site/app to post a job or use the services offered by
                                            Pill ATS for purposes related to the search for candidates for a job opening
                                            ("Employer").</li>
                                        <li>An individual seeking job opportunities in any category
                                            ("Candidate"). The site/app may be used for legal purposes of
                                            self-employment or
                                            employment of candidates for your needs within the established context of
                                            the
                                            intended and acceptable use of the pill.com.co site/app.</li>
                                    </ul>


                                    <br>
                                    </p>

                                    <h2 style="color: black; font-weight: 600; text-align: left;" class="mb-5">
                                        Information:</h2>
                                    <p style="color: black; text-align: justify;">

                                        If you are downloading, accessing, or using the service on behalf of an entity,
                                        you represent and warrant that you are authorized to accept this Agreement on
                                        behalf of such entity and agree to assume responsibility for use in compliance
                                        with this Agreement and indemnify pill.com.co for any violation of the terms and
                                        conditions.<br>
                                        User profiles contained in pill.com.co search results are created by people over
                                        whom pill.com.co has no control. We cannot guarantee that users are who they
                                        claim to be. We do not verify the accuracy or completeness of any job listings
                                        or other information presented by any user (company or candidate), including
                                        identity. pill.com.co assumes no responsibility for the content, accuracy,
                                        completeness, legality, reliability, or availability of any job listing or other
                                        data and/or information available on the site/app.<br><br>

                                        pill.com.co is not responsible for the qualifications of the candidate or the
                                        company, nor does it endorse them. If a dispute arises between the company and
                                        any candidate, pill.com.co shall be held harmless.
                                        Users, both candidates and companies, are responsible for their communications
                                        and information and for the consequences of including such information on the
                                        website. The company does not provide any warranty regarding the truthfulness,
                                        reliability, or accuracy of the information provided by you, nor does it endorse
                                        opinions against other users. You accept and declare that the trust and
                                        reliability placed by you in the information circulating on the platform will be
                                        at your own risk.<br><br>

                                        Users may not include information whose intellectual property belongs to another
                                        natural or legal person. If they do so, they must prove authorization for the
                                        use of such information. This includes placing information that reveals trade or
                                        commercial secrets.<br><br>

                                        The company authorizes users to consult, review, and use the material found on
                                        the website for their personal and non-commercial use, as established in these
                                        terms and conditions. Unauthorized use may constitute a violation of Colombian
                                        or foreign industrial property laws and other applicable laws.<br><br>

                                    </p>

                                    <h2 style="color: black; font-weight: 600; text-align: left;" class="mb-5">
                                        Prohibited Use of the Website:</h2>
                                    <p style="color: black; text-align: justify;">

                                        Including information that is not accurate, false, or misleading; containing
                                        pyramid schemes or any business opportunity that requires an advance payment.
                                        Engaging in acts intended to interfere or attempt to interfere with the proper
                                        functioning of the Website.
                                        Performing any act intended to decipher, compile, or unpack any software under
                                        which the Website operates.<br><br>

                                    </p>

                                    <h2 style="color: black; font-weight: 600; text-align: left;" class="mb-5">If you
                                        are a candidate, you acknowledge and accept:</h2>
                                    <p style="color: black; text-align: justify;">

                                        Any resume, information, date, images, photo, materials, or application
                                        information ("profile") you post, display, submit, or make available through the
                                        site/app is subject to this Agreement. However, without limiting the above,
                                        please note that any profile created on the site/app authorizes pill.com.co to
                                        make your profile available to any employer ("company").<br><br>

                                        You warrant and represent that all the information you provide is not misleading
                                        and that all the information provided during the registration, updating, and
                                        completion of the profile is accurate and complete.<br><br>

                                        Each employer is responsible for any job offers posted on the site/app.<br><br>
                                        pill.com.co does not guarantee the validity of any job posted; candidates should
                                        verify its validity in any category before taking any action that affects their
                                        current employment situation.<br><br>
                                        pill.com.co may exclude or remove any job offer from the site/app for any reason
                                        or no reason.<br><br>
                                        pill.com.co assumes no responsibility for the content, accuracy, completeness,
                                        legality, or reliability of any job offer or information in the pill.com.co
                                        search results.<br><br>
                                        The company reserves the right to offer the candidate other products or services
                                        of its own or of third parties, according to the preferences identified based on
                                        their registration or profile at any time.<br><br>
                                        As a website user, by accepting the terms and conditions and privacy policy, you
                                        agree to receive information about job offers that suit your profile or news of
                                        interest; however, as a candidate, you will not be able to apply for job offers
                                        or be visible in company searches until you register your resume.<br><br>
                                    </p>

                                    <h2 style="color: black; font-weight: 600; text-align: left;" class="mb-5">If you
                                        are an employer, you acknowledge and agree that:</h2>
                                    <p style="color: black; text-align: justify;">
                                        As a general rule, your job posting will be accessible to other users through
                                        the website/app in accordance with the specific applicable conditions. When you
                                        respond to or contact a Job Applicant, you must share that information with
                                        pill.com.co and authorize pill.com.co to share information with the candidate.
                                        We and the candidates rely on the accuracy and completeness of all the
                                        information provided during the registration process and the creation of each
                                        job offer. You warrant and represent that all information you provide to us is
                                        accurate and complete. We have no control over the content of Profiles;
                                        pill.com.co is not obligated to review any Profile, include any Profile in its
                                        search results or other listings, and may exclude or remove any Profile from the
                                        Platform for any reason. Pill.com.co assumes no responsibility and disclaims all
                                        liability for the content, accuracy, completeness, legality, reliability, or
                                        availability of any candidate included in pill.com.co's search results. The
                                        site/app may be suspended or terminated at any time, for any reason, temporarily
                                        or permanently. If you receive a notice of suspension or termination of your
                                        license to use the site/app, you must cease using it. Pill.com.co reserves the
                                        right to suspend or terminate your access and use at any time if it determines
                                        that you are in breach of the agreement. Any decision will take effect without
                                        prejudice to any damages that we may claim from you and your legal
                                        representatives, in compensation for the loss suffered as a result of any
                                        breach.<br><br>
                                    </p>

                                    <h2 style="color: black; font-weight: 600; text-align: left;" class="mb-5">Scope and
                                        Responsibility:</h2>
                                    <p style="color: black; text-align: justify;">
                                        Pill.com.co provides users with a virtual space that facilitates, through the
                                        internet, the possibility for candidates to find a job and for companies to find
                                        the candidate that fits the needs of the job offer they are seeking.<br>
                                    </p>

                                    <p style="color: black; text-align: justify;font-weight: 550;">Accordingly,
                                        pill.com.co has the following responsibilities:</p>
                                    <p style="color: black; text-align: justify;">
                                        Deny the registration of any natural or legal person at any time, for any
                                        reason.<br><br>
                                        Remove content that pill.com.co deems illegal, offensive, defamatory, or that in
                                        any way violates the terms and conditions set forth.<br><br>
                                        Use the personal information and/or content provided by the user in accordance
                                        with the authorizations given by them, and in accordance with pill.com.co's
                                        privacy policy. It is expressly stated that the use of this site/app does not
                                        create any partnership, franchise, or employment relationship between
                                        pill.com.co and the User.<br><br>
                                        This site is controlled and operated by pill.com.co from its offices in Bogotá,
                                        Colombia. Pill.com.co states that this website has been created for use by
                                        residents of Colombia and that it has been structured with the aim of complying
                                        with the imperative and public order laws of this country, as
                                        appropriate.<br><br>

                                        Pill.com.co is not aware of all the sites that may be connected to its site
                                        through “electronic links” or “links” and is not responsible for the content of
                                        any “off-site” page or other sites connected to its own. Anyone freely accessing
                                        this site or wishing to access other sites connected via links to this site does
                                        so on their own initiative.<br><br>

                                        Pill.com.co makes reasonable efforts to include up-to-date information on its
                                        website, but does not guarantee its accuracy at any time. Therefore, pill.com.co
                                        assumes no responsibility for errors or omissions in the site's content.<br><br>

                                        The materials on this site and the pages that comprise it are provided without
                                        any express or implied warranties. Pill.com.co does not guarantee that the
                                        technical and operational functions of its site will be uninterrupted or
                                        error-free, that such defects will be corrected, or that this site or the server
                                        that makes it available to the public are free of viruses or other harmful
                                        components.<br><br>

                                        Pill.com.co is not responsible for, nor involved in, any agreements made
                                        directly between employer users and candidates; it is important to note that
                                        there are risks beyond the company's control, which you as a user accept when
                                        interacting directly with them.<br><br>

                                    </p>

                                    <h2 style="color: black; font-weight: 600; text-align: left;" class="mb-5">
                                        Registration and User:</h2>
                                    <p style="color: black; text-align: justify;">



                                        To use the site/app, users must provide certain personal data. Pill.com.co is
                                        aware that the security of your information is private when using the site/app;
                                        this is an important aspect. We take the protection of your personal data very
                                        seriously, so your personal information is processed and stored on servers or
                                        magnetic media that maintain high standards of physical and technological
                                        security and protection. Therefore, we consider it important that you know the
                                        data we retain and the information we discard. For more information, please
                                        consult our Privacy Policy.<br><br>

                                        The site/app will only be available to people who have the legal capacity to
                                        contract. It is mandatory to complete the registration form in all its fields
                                        with valid data in order to use the services provided by the Site.<br><br>

                                        The user must complete their personal information accurately, precisely, and
                                        truthfully ("Personal Data") and undertakes to update the Personal Data as
                                        necessary, being responsible, in any case, for the truthfulness, accuracy,
                                        validity, and authenticity of the personal data entered.<br><br>

                                        The user is fully responsible for the content and accuracy of the details they
                                        provide, as well as for any infringement of the rights of third parties that may
                                        be involved in those details, and is aware that pill.com.co may store
                                        information and use it for statistical analysis or any other business purpose,
                                        except for information containing personal data beyond basic data or usage
                                        data.<br><br>

                                        The account is personal, unique, and non-transferable. The user will be
                                        responsible for all operations performed on their account, as access to it is
                                        restricted to the entry and use of their security password, which is exclusively
                                        known to the user.<br><br>

                                        The user undertakes to notify pill.com.co immediately, through an appropriate
                                        and reliable means, of any unauthorized use of their account, as well as any
                                        unauthorized access by third parties to it.<br><br>

                                        Pill.com.co reserves the right to deny access to the website at any time,
                                        without prior notice, to users who fail to comply with the established
                                        conditions.<br><br>

                                        The user must indemnify pill.com.co for any concept in case of any situation of
                                        non-compliance related to the terms and conditions set forth in this
                                        document.<br><br>

                                        Both candidates and companies can initiate the password recovery process through
                                        email or support request through the designated WhatsApp line.<br><br>

                                    </p>

                                    <h2 style="color: black; font-weight: 600; text-align: left;" class="mb-5">User
                                        Content:</h2>
                                    <p style="color: black; text-align: justify;">



                                        The Platform allows Users to post, send, display, or otherwise make available
                                        job offers on the site/app, Profiles, or any other information, images,
                                        messages, and other materials (“User Content”). Users may occasionally post User
                                        Content, including messages or statements that are misleading or incorrect.
                                        Pill.com.co does not endorse nor is it responsible for any opinion, advice,
                                        information, or statement contained in User Content made or displayed on the
                                        Platform by Users. You acknowledge that by accessing the Platform, you may come
                                        into contact with content that you may find inappropriate and acknowledge that
                                        pill.com.co shall have no liability to you for such content. Users must not post
                                        User Content or information that is illegal, fraudulent, discriminatory,
                                        threatening, abusive, defamatory, obscene, or otherwise objectionable, or that
                                        contains sexual, ethnic, or racial slurs or other discriminatory insults, or
                                        material that does not contain relevant or constructive content, which goes
                                        beyond profanity to obscenity or hatred, or otherwise violates the law.
                                        <br><br>Users
                                        are also prohibited from posting any User Content containing proprietary
                                        information, trade secrets, or confidential information. User Content may not
                                        contain advertisements, solicitations, chain letters, pyramid schemes,
                                        investment opportunities, or other unsolicited commercial communications (unless
                                        expressly permitted otherwise), or encourage or cause spam or flooding. You are
                                        prohibited from posting any User Content that contains official identification
                                        information (whether yours or another's) on the Platform, such as Social
                                        Security number, passport number, national identification number, insurance
                                        number, driver's license number, immigration number, or any other similar
                                        number, code, or identifier. The inclusion of such identification information in
                                        any Profile may lead to identity theft and other adverse consequences, for which
                                        pill.com.co is not responsible. Pill.com.co may remove such information but
                                        assumes no obligation to do so and assumes no responsibility and disclaims all
                                        liability for the publication of such information.<br><br> Although pill.com.co
                                        has no obligation to do so, pill.com.co may monitor User Content posted on the
                                        Platform
                                        and reserves the right to remove any User Content or parts thereof that violate
                                        the above rules, messages, topics unrelated to the specific part of the Platform
                                        on which they are posted, advertisements, recruitment, or other commercial
                                        messages, and any other User Content that pill.com.co, in its sole discretion,
                                        deems inappropriate. If you believe that a message violates our policies, please
                                        contact pill.com.co immediately so we can consider its editing or removal.
                                        Pill.com.co does not promise to delete any User Content, and the interpretation
                                        of whether User Content violates any pill.com.co policy is always at
                                        pill.com.co's sole discretion. Pill.com.co reserves the right to disclose all
                                        User Content and other relevant information and circumstances surrounding its
                                        transmission to any third party to operate the site/app and provide the Services
                                        properly; to protect itself, its partners, and its visitors; and to comply with
                                        legal obligations.
                                    </p>

                                    <h2 style="color: black; font-weight: 600; text-align: left;" class="mb-5">Employer
                                        Subscription:</h2>
                                    <p style="color: black; text-align: justify;">

                                        Pill.com.co is a platform that provides the service of creating a jobsite for
                                        the "user" and publishing job offers on
                                        its portal to find the best talent in the shortest possible time.

                                        For the above, pill.com.co offers the following subscription options for
                                        platform use:
                                    </p>
                                    <p style="color: black; text-align: justify;font-weight: 550;">Premium:</p>

                                    <p style="color: black; text-align: justify;">
                                        Service Description: Provides a full version of the platform where unlimited job
                                        postings can be made, and access to the functionalities established in the SLAs
                                        (Service Level Agreements), which will be provided once negotiations are
                                        completed with the assigned sales representative.<br><br>
                                        Premium Version Updates: Users will have access to improvements and updates made
                                        to enhance the product.<br><br>
                                        Privacy: With the acquisition of the subscription, the employer commits to
                                        protecting users' privacy and complying with our privacy policy to learn more
                                        about how we handle user information.<br><br>
                                        Cancellation and Termination of Service: Users can cancel their account at any
                                        time with 30 business days' notice. In case of non-payment of the subscription
                                        as agreed between the parties, pill.com.co reserves the right to suspend or
                                        terminate access to the service or if these terms and conditions are violated.
                                        Term: Subscriptions are annual, and payments will be made monthly.<br>
                                    </p>
                                    <p style="color: black; text-align: justify;font-weight: 550;">Freemium:</p>

                                    <p style="color: black; text-align: justify;">
                                        Service Description: Provides a basic version of the platform where up to 2 job
                                        postings can be made, and access to up to 5 candidate profiles who have applied
                                        to the posted job. Contact can be made through the pill.com.co platform, and
                                        only one user per company is allowed.<br><br>
                                        Privacy: With the acquisition of the subscription, the employer commits to
                                        protecting users' privacy and complying with our privacy policy to learn more
                                        about how we handle user information.<br><br>
                                        Cancellation and Termination of Service: The service will be suspended 30
                                        calendar days from the date of creation of the company's profile.
                                        Acceptable Use: Users commit to using the service in a legal and ethical manner.
                                        The use of the service for illegal, abusive activities or activities that
                                        violate third-party rights is prohibited.<br><br>
                                        Liability: pill.com.co will not be responsible for any direct, indirect,
                                        incidental, special, consequential, or punitive damages arising from the use or
                                        inability to use the service.<br><br>
                                        Contact Authorization: The employer authorizes being contacted by email, phone,
                                        or WhatsApp by a sales representative to offer the premium service.
                                        Electronic Communications and Alerts: The Website provides "The User" with a
                                        free job alert service that will be sent to the user's email or WhatsApp upon
                                        registration through the corresponding form. Users can modify or delete alert
                                        services easily, quickly, and for free, by following the customer service
                                        channels that will indicate the process to follow.<br><br>
                                        WhatsApp: The WhatsApp contact feature is enabled for communication with
                                        candidates who wish to register for the first time or who, while registered, can
                                        receive various notifications for job placement; the candidate, upon acceptance,
                                        authorizes the employer to contact them directly through the platform.<br><br>

                                        Copyright and Copyright Rights: This site/app is owned by pill.com.co, domiciled
                                        in Bogotá, Colombia, hereinafter "Pill ATS," "we," or "our." No material from
                                        http://www.pill.com.co/ ("site") may be copied, reproduced, published, affixed,
                                        transmitted, downloaded from the site/app, or distributed in any way,
                                        unless:<br><br>

                                        It is used for informational purposes only.<br><br>
                                        The material is not used for commercial purposes.<br><br>
                                        Any copy of this material, in whole or in part, must include Pill ATS copyright
                                        clauses, preserving intact the Know How copyright and/or other notices of Pill
                                        ATS as the site owner.<br><br>
                                        The information, photographs, and illustrations published on this site are the
                                        exclusive property of Pill ATS or properly licensed to Pill ATS by its owner.
                                        Any modification, reproduction, total or partial representation of the materials
                                        or documents, or use of them for any purpose without the express written consent
                                        of Pill ATS is a violation of Pill ATS copyright and/or copyright
                                        rights.<br><br>

                                        Pill ATS logos, trade slogans, and other trademarks are the property of Pill ATS
                                        and/or its affiliates or subsidiaries worldwide. All trade names, slogans,
                                        and/or trademarks mentioned in banners or advertisements other than Pill ATS are
                                        trademarks of their respective owners. Any unauthorized use of these trademarks
                                        or other materials is expressly prohibited and constitutes a violation of
                                        copyright and industrial property regulations.<br><br>

                                        Links to Third-Party Sites: This site contains links/references to third-party
                                        sites. By providing such links, Pill ATS is not endorsing their contents. Pill
                                        ATS assumes no responsibility for the availability or content of such sites nor
                                        for any damages or injuries resulting in any way from the use of such contents
                                        by the user. Links to other sites are provided to users for their convenience.
                                        Users access such sites at their own risk and are responsible for compliance
                                        with their local laws if applicable.<br><br>

                                        Disclaimers and Limitations of Liability: No representations or warranties,
                                        either express or implied, are made regarding the completeness, accuracy, or
                                        functionality of the site/app.<br><br>

                                        Pill ATS makes every effort to ensure high quality and reliability; however, it
                                        acknowledges that the use of communications and the internet structure may
                                        introduce undesirable conditions where the site/app may not function,
                                        malfunction, or introduce errors or malicious codes to its users.<br><br>

                                        Pill ATS is not responsible for damages resulting from the use or inability to
                                        use, especially - but not limited to - in cases of external impact, such as
                                        damaged hardware, lack of internet access, lack of available signal, etc. Pill
                                        ATS will not be liable for any errors, omissions, or outdated material on the
                                        app and is not obliged to keep the site/app permanently available.<br><br>

                                        Pill ATS will not be responsible for any direct or indirect damage or harm that
                                        the user may suffer as a result of accessing the site/app, using, applying,
                                        and/or processing the information, including liability for damages caused by
                                        viruses that infect the system(s).<br><br>

                                        Pill ATS is responsible for deficiencies in the data transmission
                                        network.<br><br>

                                        Pill ATS reserves the right to take down the site/app at any time.<br><br>

                                        Attention to Questions, Complaints, Claims, and Suggestions: Users of
                                        pill.com.co can submit their questions, complaints, and claims via email at
                                        <b>pill.startup@gmail.com</b> Any request received through this contact method
                                        will be addressed within thirty (30) business days of its receipt.<br><br>
                                    </p>

                                </v-col>
                            </v-row>

                        </v-card>
                    </center>
                </v-container>

            </v-main>
            <Footer />

        </v-app>
    </div>
</template>

<script>
import Header from '@/components/components_landing_home/headerHome.vue'
import Footer from '@/components/components_landing_home/Footer.vue'

// @ is an alias to /src
export default {
    name: 'termsView',
    components: {
        Header,
        Footer
    },
}
</script>

<style scoped>
.v-btn {
    text-transform: none;
}

.intro-text-head {
    color: black;
    font-size: 40px;
    line-height: 65px;
    text-align: center;
    font-weight: 600;
}

.intro-text-head-mb {
    color: black;
    font-size: 37px;
    line-height: 45px;
    font-weight: 600;

}

.intro-text-head-desc {
    color: black;
    font-size: 18px;
    font-weight: 400;
}

.intro-text-head-desc-mb {
    color: black;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}

.glass-card {
    border: none;
    background-color: rgba(255, 255, 255, 0.3);
}

.glass-card::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-image: linear-gradient(45deg, rgb(255, 255, 255), black) 1;
    border-radius: 20px;
}
</style>